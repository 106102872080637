var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticStyle:{"z-index":"10"},attrs:{"id":"work"}},[_c('v-container',[_c('hr'),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticStyle:{"padding-top":"40px"},attrs:{"elevation":0,"color":"transparent","tile":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-title',{staticClass:"text-h4 black--text primary--text text-left mb-5",staticStyle:{"line-height":"1.2 !important"},attrs:{"data-aos":"fade-in","data-aos-duration":"400"}},[_vm._v(" Our work ")]),_c('div',{staticClass:"feed-control full",staticStyle:{"height":"220px !important"}})],1)],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"loading-box work-box",attrs:{"data-aos":"fade-in","data-aos-duration":"400"}},[(_vm.feeds.length)?_c('VueSlickCarousel',_vm._b({ref:"carousel",attrs:{"id":"work-slider"},on:{"init":_vm.onInit,"reInit":_vm.onUpdate,"beforeChange":_vm.onBeforeChange,"afterChange":_vm.onAfterChange}},'VueSlickCarousel',_vm.settings,false),_vm._l((_vm.feeds),function(feed,idx){return _c('div',{key:idx,class:{
                      'video-item hovered-item': _vm.hover,
                      'video-item': !_vm.hover,
                    },attrs:{"data-id":idx},on:{"mouseover":function($event){$event.stopPropagation();return _vm.selectIndex(idx)},"mouseleave":function($event){return _vm.unselect(idx)}}},[(_vm.isLoading)?_c('v-skeleton-loader',{attrs:{"type":"image","height":"180"}}):_vm._e(),(!_vm.isLoading)?_c('div',{staticClass:"work-content"},[_c('v-overlay',{staticStyle:{"border-radius":"20px"},attrs:{"value":"true","color":"grey","opacity":".4","absolute":""}}),_vm._l((_vm.clients),function(client,idx){return _c('div',{key:idx},[(feed.perusahaan == client.id)?_c('div',{staticClass:"work-brand"},[_c('v-img',{staticClass:"brand-img fill-height",staticStyle:{"display":"block !important"},attrs:{"height":"30","src":'https://administrator.missingpiece-agency.com/img/' +
                              client.logo,"aspect-ratio":"1","contain":""}})],1):_vm._e()])}),_c('v-img',{staticClass:"fill-height",attrs:{"height":"180","src":'https://administrator.missingpiece-agency.com/img/' +
                          feed.poster,"lazy-src":'https://administrator.missingpiece-agency.com/img/' +
                          feed.poster,"aspect-ratio":"2","cover":""}})],2):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.ItemIndex == idx),expression:"ItemIndex == idx"}],staticClass:"video-hover",attrs:{"data-id":idx,"id":'hover-' + idx,"data-src":feed.linkyoutube +
                        '?origin=https://missingpiece-agency.com&rel=0&autohide=2&vq=hd1080&modestbranding=0&showinfo=0',"data-poster":'https://administrator.missingpiece-agency.com/img/' +
                        feed.poster}},[_c('div',{staticClass:"work-content"},[_c('video',{ref:"videoPlayer",refInFor:true,staticClass:"video-js",attrs:{"data-id":idx,"id":'video-' + idx,"src":feed.linkyoutube +
                            '?origin=https://missingpiece-agency.com&rel=0&autohide=2&vq=hd1080&modestbranding=0&showinfo=0&controls=0',"poster":'https://administrator.missingpiece-agency.com/img/' +
                            feed.poster,"loop":"","muted":"","controls":"","preload":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":feed.linkyoutube +
                              '?origin=https://missingpiece-agency.com&rel=0&autohide=2&vq=hd1080&modestbranding=0&showinfo=0',"poster":'https://administrator.missingpiece-agency.com/img/' +
                              feed.poster,"type":"video/youtube"}})]),_c('div',{staticClass:"work-description"},[_c('v-btn',{staticClass:"btn-play rounded-2",attrs:{"small":"","color":"white"}},[_c('v-icon',[_vm._v("mdi-play")])],1),_c('div',{staticClass:"text-p ml-2"},[_vm._v(_vm._s(feed.judul))])],1)])])],1)}),0):_vm._e()],1)])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }